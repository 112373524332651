<!--
 * @Author: mjzhu
 * @Date: 2022-06-09 10:11:22
 * @LastEditTime: 2024-04-16 19:52:16
 * @FilePath: \awx-ui\src\pages\securityCenter\master.vue
-->

<template>
  <div class="authorization-page">
      <a-row type="flex" align="middle">
        <a-col :span="8">
          <a-button @click="onSearch">刷新</a-button>
          <!-- <a-button class="mgl10" type="primary" icon="plus" @click="createMaster({})">注册</a-button> -->
          <a-button class="mgl10" type="primary" icon="plus" style="z-index: 1" @click="importHost({})">新建节点</a-button>
        </a-col>
        <a-col :span="16" style="text-align: right">
          <a-input-search placeholder="请输入关键词查询" class="w470" @change="(value) => getVal(value, 'name')" allowClear @search="onSearch" />
          <!-- <a-input placeholder="请输入电话" class="w252 mgr12" @change="(value) => getVal(value, 'phone')" allowClear /> -->
          <!-- <a-button type="primary" icon="search" @click="onSearch"></a-button> -->
        </a-col>
        <a-col :span="2" style="text-align: right">
        </a-col>
      </a-row>
      <div class="table-info">
        <a-table class="table-15 opt-table" @change="tableChange" :customRow="handleClickRow" :columns="columns" :loading="loading" :dataSource="dataSource" rowKey="id" :pagination="pagination"></a-table>
      </div>
  </div>
</template>

<script>
import CreateMasterPage from "./components/createMaster.vue";
import DelectMasterPage from "./components/delectMaster.vue";
import HostEmpty from "@/components/empty-host/index.vue";
import { mapActions, mapState, mapGetters} from "vuex";
import moment from 'moment';

export default {
  name: "master",
  data() {
    return {
      params: {},
      setSelectedIndex: -1,
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
        showTotal: (total) => `共 ${total} 条`,
      },
      dataSource: [],
      loading: false,
      columns: [
        {
          title: "序号",
          key: "index",
          width: 70,
          customRender: (text, row, index) => {
            return (
              <span>
                {parseInt(
                  this.pagination.current === 1
                    ? index + 1
                    : index +
                        1 +
                        this.pagination.pageSize * (this.pagination.current - 1)
                )}
              </span>
            );
          },
        },
        { title: "名称", key: "name", dataIndex: "name",   
          ellipsis: true,
          width: 120,
          customRender: (text) => {
            return <span title={text || '-'}>{text || '-'}</span>;
          }, },
        {
          title: "状态",
          key: "status",
          dataIndex: "status",
          width: 90,
          customRender: (text) => {
            return text ? <span class={['status-text', ['enabled', 'online'].includes(text) ? 'success-text' : 'fail-text']}>{text === 'enabled' ? '启用' : text === 'online' ? '在线' : text === 'disabled' ? '停用' : '离线'}</span> : <span> '-'</span>
          },
        },
        {
          title: "是否默认网络",
          key: "is_default",
          dataIndex: "is_default",
          width: 120,
          customRender: (text, row) => {
            return <span>
              <a-switch size={'large'} checked={text} checked-children="是" un-checked-children="是" onChange={(val, e) => this.changeEnable(row, val, e)}></a-switch>
            </span>;
          },
        },
        {
          title: "检测时间",
          key: "check_time",
          width: 180,
          dataIndex: "check_time",
          customRender: (text) => {
            return <span>{text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : '-'}</span>;
          },
        },
        {
          title: "创建时间",
          key: "create_time",
          dataIndex: "create_time",
          width: 180,
          customRender: (text) => {
            return <span>{text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : '-'}</span>;
          },
        },
        {
          title: "接入凭证",
          key: "access_key",
          dataIndex: "access_key",
          customRender: (text) => {
            return <span>{text ? text : '-'}<a style='margin-left: 10px;font-size: 12px' onClick={(e) => this.copyText(text, e)}>复制</a></span>;
          },
        },
        {
          title: "内部地址",
          key: "private_addr",
          dataIndex: "private_addr",
          ellipsis: true,
          width: 180,
          customRender: (text) => {
            return<span onClick={(e) => e.stopPropagation()}>
              <a href={text || '-'} target="_blank" title={text || '-'}>{text || '-'}</a>
            </span>
          },
        },
        {
          title: "外部地址",
          key: "public_addr",
          dataIndex: "public_addr",
          ellipsis: true,
          customRender: (text) => {
            return<span onClick={(e) => e.stopPropagation()}>
              <a href={text || '-'} target="_blank" title={text || '-'}>{text || '-'}</a>
            </span>
          },
        },
        {
          title: "机器码",
          key: "machine_code",
          dataIndex: "machine_code",
          ellipsis: true,
          customRender: (text) => {
            return <span title={text || '-'}>{text || '-'}</span>
          },
        },
        {
          title: "描述",
          key: "description",
          dataIndex: "description",
          ellipsis: true,
          width: 120,
          customRender: (text) => {
            return <span title={text || '-'}>{text || '-'}</span>;
          },
        },
        {
          title: "操作",
          key: "action",
          width: 100,
          customRender: (text, row, index) => {
            return (
              <span class="flex-container" onClick={(e) => e.stopPropagation()}>
                {
                  row.status !== 'disabled' ?
                    <a-popconfirm
                      title="确定禁用吗"
                      ok-text="确定"
                      placement="left"
                      cancel-text="取消"
                      overlayClassName="master-disable"
                      onConfirm={(e) => this.enable(row, 'disable', e)}
                    >
                      <a-switch size={'large'} checked={true} checked-children="启用" un-checked-children="启用"></a-switch>
                    </a-popconfirm>
                    : <a-switch size={'large'} checked={false} checked-children="启用" un-checked-children="启用" onChange={(val, e) => this.enable(row, 'enable', e)}></a-switch>
                }
              </span>
            )
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters("masterMd", ["hasMaster"]),
  },
  methods: {
    ...mapActions("setting", ["setMasterOptions"]),
    handleClickRow(record, index){
      return {
        style: {
        'background-color': index === this.setSelectedIndex ? '#e4eefd ' : '#fff',
        },
        on: {
          click: () => {
          this.setSelectedIndex = index
          this.createMaster(record)
          }
        }
      }
    },
    changeEnable (row, val, e) {
      if (e) e.stopPropagation()
      this.handleSubmit(val, row)
    },
    handleSubmit(val, row) {
      console.log(val)
      const _this = this;
      const params = {
        is_default: val,
        name: row.name,
        description: row.description,
      };
      this.loading = true;
      params.id = row.id;
      this.$axiosPatch(global.API.getMasterInfo+'/'+ row.id, params)
        .then((res) => {
          this.loading = false;
          if ([200, 201, 204, 202].includes(res.status)) {
            this.$message.success("操作成功", 2);
            _this.getMasterList();
          }
        })
        .catch((err) => {});
    },
    copyText (text, e) {
      global.utils.copyText(text, e);
    },
    clearSelectIndex () {
      this.setSelectedIndex = -1
    },
    tableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getMasterList();
    },
    getVal(val, filed) {
      this.params[`${filed}`] = val.target.value;
    },
    //   查询
    onSearch() {
      this.pagination.current = 1;
      this.getMasterList();
    },
    importHost() {
      const self = this;
      let width = 1081;
      let title = "";
      let content = (
        <HostEmpty
          style="height: 650px"
          hasMaster={this.hasMaster}
          isMasterAdd={true}
          creatMaster={() => {
            self.onSearch();
            self.$destroyAll()
          }}
        />
      );
      this.$confirm({
        width: width,
        class: "host-empty-dialog master-host-empty",
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    createMaster(obj, e) {
      if (e) e.stopPropagation()
      const self = this;
      let width = 520;
      let title = JSON.stringify(obj) === "{}" ? "注册master" : "编辑matser";
      let content = (
        <CreateMasterPage detail={obj}  clearSelectIndex={() => self.clearSelectIndex()} callBack={() => self.getMasterList()} />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        onCancel: () => {
          self.clearSelectIndex()
        },
        icon: () => {
          return <div />;
        },
      });
    },
    enable (row, type, e) {
      if (e) e.stopPropagation()
      this.$axiosPatch(global.API.getMasterInfo + `/${row.id}/` + type, {})
        .then((res) => {
          this.loading = false;
          if ([200, 201, 204, 202].includes(res.status)) {
            this.$message.success("操作成功", 2);
            this.getMasterList()
          }
        })
        .catch((err) => {});
    },
    delectMaster(obj, e) {
      if (e) e.stopPropagation()
      const self = this;
      let width = 400;
      let content = (
        <DelectMasterPage
          sysTypeTxt="master"
          detail={obj}
          callBack={() => self.getMasterList()}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              <a-icon
                type="question-circle"
                style="color:#2F7FD1 !important;margin-right:10px"
              />
              提示
            </div>
          );
        },
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    getMasterList() {
      this.loading = true;
      const params = {
        page_size: this.pagination.pageSize,
        page: this.pagination.current,
        keyword: this.params.name || "",
        // phone: this.params.phone || "",
      };
      this.$axiosGet(global.API.getMasterInfo, params).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.dataSource = res.data.results;
          this.pagination.total = res.data.total;
          const onlineCluster = res.data.results.filter(item => item.status === 'online')
          let DefaultMaster = {} 
          if (onlineCluster.length > 0) {
            const arr  = onlineCluster.filter(item => item.is_default)
            if (arr.length > 0) {
              DefaultMaster = arr[0]
            } else {
              DefaultMaster = onlineCluster[0]
            }
          }
          localStorage.setItem('masterOptions', JSON.stringify(res.data.results))
          localStorage.setItem('FILE_SYSTEM_URL', DefaultMaster?.public_addr  || DefaultMaster?.private_addr || '')
          window.config.FILE_SYSTEM_URL = DefaultMaster.public_addr  || DefaultMaster.private_addr || ''
          this.setMasterOptions(res.data.results)
        }
      });
    },
  },
  mounted() {
    this.getMasterList();
  },
};
</script>

<style lang="less" scoped>
.authorization-page {
  height: calc(100vh - 48px);
  box-shadow: none;
  padding: 16px 20px 20px 20px;
  .table-info {
    margin-top: 10px;
  }
.btn-opt {
    border-radius: 2px;
    font-size: 12px;
    color: #0264c8;
    letter-spacing: 0;
    font-weight: 400;
  }
}
.status-text {
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: #fff;
}
.success-text {
  background: #57a850;
}
.fail-text {
  background: #ED4F50;
}
</style>
