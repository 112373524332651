<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 11:38:30
 * @LastEditTime: 2024-01-18 17:26:02
 * @FilePath: \awx-ui\src\pages\securityCenter\components\createMaster.vue
-->
<template>
  <div style="padding-top: 10px">
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol" :form="form" class="p0-32-10-32 form-content">
      <a-form-item label="名称">
        <a-input v-decorator="[
            'name',
            { rules: [{ required: true, message: '名称不能为空!' }, { validator: checkName }] },
          ]" placeholder="请输入名称" />
      </a-form-item>
      <a-form-item label="描述">
        <!-- {pattern: new RegExp(/\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}/), message: '请输入正确的邮箱地址'} -->
        <a-input v-decorator="['description',{ rules: [{ required: true, message: '描述不能为空!' },
        ] }]" placeholder="请输入描述" />
      </a-form-item>
      <!-- <a-form-item label="端点信息">
        <a-input v-decorator="['endpoint',{ rules: [{ required: true, message: '端点信息不能为空!' }] }]" placeholder="请输入端点信息" />
      </a-form-item> -->
      <!-- <a-form-item label="用户">
        <a-select showSearch allowClear
          v-decorator="['username', {rules: [{ required: true, message: '用户不能为空' }]}]" placeholder="请选择用户"  >
          <a-select-option v-for="list in userList" :key="list.username" :value="list.username"  :title="list.username">  {{list.username}} </a-select-option>
        </a-select>
      </a-form-item> -->
      <!-- <a-form-item label="" :wrapper-col="{
           xs: { span: 24 },
            sm: { span: 18, offset: 6 },
      }"> 
        <a-checkbox
          v-decorator="[
            'is_default',
            {
              valuePropName: 'checked',
            },
          ]"
        >
        是否默认集群
        </a-checkbox>
    </a-form-item> -->

      <a-form-item label="密码" v-if="!editFlag">
        <a-input type="password" v-if="!editFlag&&!isShow" :disabled="editFlag" v-decorator="['password',{ rules: [{ required: !editFlag, message: '密码不能为空!' }] }]" placeholder="请输入密码" />
        <a-input v-if="!editFlag&&isShow" :disabled="editFlag" v-decorator="['password',{ rules: [{ required: !editFlag, message: '密码不能为空!' }] }]" placeholder="请输入密码" />
        <a-icon :type="!isShow ? 'eye-invisible':'eye'" @click.stop="isShow=!isShow" style="position: absolute;cursor: pointer;right: 10px;top: 1px;"/>
      </a-form-item>
    </a-form>
    <div class="ant-modal-confirm-btns-new">
      <a-button style="margin-right: 10px" type="primary" @click.stop="handleSubmit" :loading="loading">确认</a-button>
      <a-button @click.stop="formCancel">取消</a-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    callBack: Function,
    clearSelectIndex: Function
  },
  data() {
    return {
      isShow:false,
      editFlag: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      form: this.$form.createForm(this),
      loading: false,
      userList:[]
    };
  },
  watch: {},
  methods: {
    checkName(rule, value, callback) {
      var reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g;
      if (reg.test(value)) {
        callback(new Error("名称中不能包含中文"));
      }
      if (/\s/g.test(value)) {
        callback(new Error("名称中不能包含空格"));
      }
      callback();
    },
    formCancel() {
      this.clearSelectIndex()
      this.$destroyAll();
    },
    handleSubmit(e) {
      const _this = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(values);
        if (!err) {
          const params = {
              is_default: values.is_default,
              name: values.name,
              password: values.password,
              description: values.description,
              // endpoint: values.endpoint,
          };
          this.loading = true;
          if (JSON.stringify(this.detail) !== "{}") {
            delete params.password;
            params.id = this.detail.id;
            this.$axiosPatch(global.API.getMasterInfo+'/'+this.detail.id, params)
              .then((res) => {
                this.loading = false;
                if ([200, 201, 204, 202].includes(res.status)) {
                  this.$message.success("保存成功", 2);
                  this.$destroyAll();
                  _this.clearSelectIndex();
                  _this.callBack();
                }
              })
              .catch((err) => {});
            return false;
          }
          this.$axiosJsonPost(global.API.getMasterInfo, params)
            .then((res) => {
              this.loading = false;
              if ([200, 201, 204, 202].includes(res.status)) {
                this.$message.success("保存成功", 2);
                this.$destroyAll();
                _this.clearSelectIndex();
                _this.callBack();
              }
            })
            .catch((err) => {});
        }
      });
    },
    echoUSer() {
      this.$axiosGet(global.API.getUserList, {}).then(
        (res) => {
          console.log(res)
          this.userList = res.data.results || [];
        }
      );
      if (JSON.stringify(this.detail) !== "{}" && this.detail.id) {
        this.editFlag = true;
            this.form.getFieldsValue([
              "is_default",
              "name",
              'description',
              "password",
              // "endpoint",
            ]);
            this.form.setFieldsValue({
              is_default: this.detail.is_default,
              name: this.detail.name,
              password: "default",
              description: this.detail.description,
              // endpoint: this.detail.endpoint,
            });
      } else {
        this.form.getFieldsValue([
              "is_default",
              "name",
              'description',
              "password",
              // "endpoint",
            ]);
        this.form.setFieldsValue({
              is_default: undefined,
              name: '',
              password: '',
              description: '',
              // endpoint: '',
        });
      }
    },
  },
  mounted() {
    this.echoUSer();
  },
};
</script>
<style lang="less" scoped>
</style>
